@charset "utf-8";

@import "variables.scss";

// 资讯
.info-set-tagicons{ margin-top: 16px;
  .magx-upload-multi-imgs{ float: left;}
  .tag-item{ margin-bottom: 12px;
    .uploaded-imgs{ width: 54px; height: 54px; }
  }
  .btn-del-newtag,
  .magx-textfield{ float:left; }
  .magx-textfield{ width: 302px; margin:8px 0 0 18px; }
  .btn-del-newtag{ margin:8px 0 0 24px; line-height: 32px; }
}
.magx-list{
  &.infolist{ margin: 10px 0 0 0;
    .magx-table td{ padding: 10px 0; }
    .magx-table tbody td{vertical-align: initial; 
      &:first-child, &:nth-of-type(6){ cursor: pointer; }
    }

    .posted-data{ padding-right: 50px;
       .title{
        i{ display: inline-block; border: 5px solid transparent; }
        .am-icon-caret-right{ border-left-color: #333; }
        .am-icon-caret-down{ border-top-color: #333; }
       }
    }
    .images-hgroup.has-imgs .item{ width:80px; height: 60px; overflow: hidden; }
    .images-hgroup.has-img .item{ width:248px; height: 120px; overflow: hidden; position: relative;
      img{ position: absolute; left:0; top: 50%; -webkit-transform:translate(0, -50%); transform:translate(0, -50%); }
    }
    .btn-hspace{ margin-left: 32px; }
  }
}
.info-column-link{ float: left; margin:3px 0 0 10px; position: relative;
  &>button{ border:1px solid $gray-lighter; height: 20px; padding: 0 10px; color:$gray;
    &:hover,
    &.active{ border-color:#D9D9D9; background-color: #D9D9D9; }
  }
  .magx-overlay{ top:24px; left:20px; }
  .magx-dialog{ padding: 18px 18px 24px 18px;}
  .magx-form .magx-textfield{ color:$gray; }
  .btn-group{ margin-top: 8px; }
}
.info-dock{ margin-bottom: -20px;
  .platform{ padding: 24px 0; position: relative; min-height: 48px;
    &:after{ content: '\20'; position: absolute; height: 1px; left: 90px; bottom: 0; right:0; background-color: $single-line-color; }
    &:last-child:after{ display: none; }
  }
  .platform-sum{
    .image{ float: left;border-radius: 6px; margin-right: 20px; }      
    .text{ margin-right: 100px; max-width:640px; line-height: 1.6; overflow: hidden; }
    .magx-btn.big{ position: absolute; right:24px; top:24px; }
  }
  .platform-form{ margin-top: 10px; }
}
.info-post-rebuild-readnum{ float: left; line-height: 32px; margin-left: 16px;
  .magx-btn-text{ font-size: 12px; margin-right: 6px; }
}
.magx-form-infopost{ margin-top: 10px;
  .control .info{ margin-top: 8px; }
  .info-timer{ margin: 16px 0 0 12px; }
}
.info-post-title{ width: 480px; height: 62px; font-size: 14px; }

.infolist-push-time{
  .current{ padding: 4px 10px; background-color: #42bd56; color: #fff; }
}