@charset "utf-8";

// ***** basic variables

// 全局主题色，包括文本、边框、背景
$theme-color: #42BD56;

// 超链接文本正常色
$anchor-normal-color: #333333;
// 超链接文本hover(pc端)\点击态(移动端)色
$anchor-active-color: $theme-color;

// 文本颜色灰度，这里5个级别作为参考
$gray-dark: #333333;
$gray: #666;
$gray-light: #999;
$gray-lighter: #ccc;
$gray-lightest: #E6E6E6;

// 0.5px线的颜色
$hairline-color: #ccc;

// 单行底边颜色
$single-line-color: #e8e8e8;

// 定义body元素文本颜色、文本大小以及页面背景色
$body-font-color: $gray-dark;
$body-font-size: 12px;
$body-bg-color: #FFFFFF;

// 全局图片默认背景色
$img-default-bgcolor: #F1F1F1;

// 表单控件，包括单行、多行输入框，plhd代表placeholder
$form-control-color:  $gray-dark;
$form-control-plhd-color-n: $gray-lighter;
$form-control-plhd-color-f: $gray-dark;
$form-control-bg-color: #FFFFFF;
$form-control-border-color: $gray-lighter;
$form-control-font-size: 12px;
$form-control-border-radius: 2px;

// 详情页通过后台发布内容，包括行高、文本大小、图片上下边距
$html-data-line-height: 28px;
$html-data-img-margintb: 8px;

// 超链接hover、按钮点击时过渡效果持续时间
$transition-durition: 0.2s;

// 通用固定工具栏
$toolbar-bg-color: rgba(255,255,255,.96);
$toolbar-top-height: 40px;
$toolbar-bottom-height: 49px;
// 通用覆盖层背景色
$overlay-bg-color: rgba(0,0,0,.7);
// 通用对话框背景色
$dialog-bg-color: #FFFFFF;
// 通用对话框圆角
$dialog-border-radius: 0;

// 页面覆盖层z-index说明
$z-index-overlay: 1999;
$z-index-drop-menu: 2000;

// 低于10kb图片使用base64编码格式
$base64-icon-close-dialog: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAAXNSR0IArs4c6QAAALBJREFUGBlj3LRp07x///5dDggI6GdAAxs3bswHChkysbCw1DEyMmZt2LChEFkNVEEeBwdHLSNIYtu2bTK/f//e/////2kgE5EUOLi7uz8GK0JTeAVosh7QBLACkBwTiAABLy+vJ0DJZUBmABAvA5kAEgcBuCKQFUDrYoButAaKRyC7EawI2Q0+Pj7HWFlZHZE9wwjUkQcUyEd2A8gKZM8wARUYoysAKQK5EWQikGkIAMolVw3SOZl+AAAAAElFTkSuQmCC';

$base64-icon-img-plhd: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAANCAYAAABLjFUnAAAAAXNSR0IArs4c6QAAAVVJREFUKBW9UbFOg1AUBYSkaQpfYGLSTZsw+B9N/ACIwMCms3t1cbImkrgQwuqkce4PdCPp6uaoAzGkCRU8hwB5IC4dvMnl3nvufec9zj2QYJZl3Zim+QyfJEmyIraPKfWhS8QJnHFva8juwfAFZ/w/s217Th+6UR4C/8IcxxntdrsN+6qqzqIo2oqzzW+KmISbHSzlrAOiKIriCmFKr/POyK+Xua57gtvXZVl+GoZxHAQBtZQ8z5vmeb4BPmIty/JW07RZGIZvrGmdl/m+P8aBJxwYo3eYpul1NYUP8GVDRIw5sabP2HkZfi0Cds5Gbd+Kohzh4Cn8pQHFiP48juNXYu3LqBNqkYi/ssLwB4juODxk0G7JxbBXkVEn5A/iMIhyaHIhiC62xbxdhkydsixbo0vC1kB2C7JHUfS22UuaZSggCtDrE73rur7oi97jaEvIUC3jBxMJkrq3SoDZAAAAAElFTkSuQmCC';

$base64-icon-file-plhd: 'data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAABGdBTUEAALGPC/xhBQAAAQ1JREFUSA1jYBgFoyFAoxBghJnb0NDAdu/evaZ///7FAcUkYeLINCMj42oWFpbs+fPnv0YWJ4fNDNMkKyvb8v///wognxcmhoXWBqpJ0NfXf3Dp0qVrWOSJFoL7OCYm5ilQlxSxOin1PROSRURbCtID9Hnonz9/rsbGxoYimUE0E9nH/4nWRbrC50xMTIuUlJTqgGnpF0g7C+lmkKVDEphoy4GJF6QZlI4YkIMaxKcpgOYYsB10tRhoIzyb0ttieIiOWgwPClozRoOa1iEMN380qOFBQWsGclA/o7VlQPOfw+yAWwys2BfBBGlFg6pGmNlwi5WVleuBlncAJWjh82dASztB9THM4lF6+IcAAPgrQENuw8eEAAAAAElFTkSuQmCC';

$base64-icon-checkbox-arr: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAAAXNSR0IArs4c6QAAAFpJREFUGBljYMAB/v//7wPEQjikGRiAkpFA/AeIzwMxB1ghkGEM0wFkwxQAmf9rYQoKQTwgKAViTAUgVUCJRCD+C8QgAKMhJsCMx6IQUwFMMdAUkIk1MD4yDQBGxWx4PFX3ZAAAAABJRU5ErkJggg==';

$base64-icon-delete-uploaded-img: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAMhJREFUKBWNkj0OwjAMhf2sSv3hBJyBmXZiQRyDjZ9DIdg4BmJhojtn4ASQdqmJDWUoaUWWyM+frdgvoM8REVT5aE2QFUgmKgvhRoJDUj72AEQ1WGKWjeu6OYrQXOPuAegcx7zE5Xln7TwEa7E2ejMCuGm2IWp23a7hmLesbw4nA6pnIx3QpvH5pHQBiqjKU9OV5SAxIEa2OpJCmbZTH68s6577gB/ds7C1Fumpz4O2yLy4ugWrg2aKN6dNdu+vcZ41pxX492u8AFupXR16NvurAAAAAElFTkSuQmCC';

$base64-icon-timepicker: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAcJJREFUOBGNk89KAlEUxp1xRiqDegI3QWTLnkEpWtcqSbQJQSiUqIeIwqQI/JOziFr0AJHVO7SLapdPYH8XOjr9jt0xs0Vz4XDu+c73nXvvOTNaYGjVarVZx3Es13XjpCIq3dA07cYwjGo6nb4flGheUCqVTEj7xFnE7/gr7Enlp/EL5Mfxx+S3MplMW3K9Akp8SSIGZofD4VwikXgVgrfgTLAvYCkK3cJdlCKGEORkJV4BPBcMwRzuIhgMLluWdQf+QpwGF/GZaIg39UqlEmWTxWxPzF6KjuCmut3uqMTeUhybOCtanWrSMHlz3iP58Hk0H6KVAnFOq6sr+tAGAopbF62OQkblddtXASFx6CMuYrBxsf44hyvINcvl8ryHm6ZZSKVSTaVxZQoNGiVz/rV0XXfA3wCXBhOdTqdG3BQNRRoaY9kDWMcifvtg2/Zkq9V6RlORHlSpFMYXMV8L8YHSVHVOfUB1hCWZa+K/CoqTFI1oe18ijdoGiPKuU54UC4VCOWnUYDG5drvdLsJZBb9Wmu9/QYgITdwuV9vAf2Iy5954waTJMokxsEP8Dqf//EwA/UWhGQRrEP/8zmAn6sl9/hcJ1NlmledIYgAAAABJRU5ErkJggg==';

// ✅
$base64-icon-nike: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAALGPC/xhBQAAAWBJREFUOBGVk79KA0EQxr89o5WdiJ21GLG0FSIX8DEEG9sUFoKdpdgFG8FCbXwBIUh8BoVYWaWRvIGI5Px9WS+XmD9cBuZmZ/b7ZuZ2Z4P+S1tVZTomXFfQ5mA7Uxfbwr9RTZ1RShg6Ha2opyvIJ8SSYXx80SfJtTbUoMy3t2KCSH6CXBvHz/CC2iQ5dJJYKVYuR3ZOFzIHCXrRjvp6ZT2rbeNGpYezPggE7SaQfWDlyIkuwF7+4RMfdgUnRedL0Be9HgHKsPd8c6knBOJV5aGgj3w5sEGfqmgfkrt9wLpoFLiTrWeApAP0neTP6J5+tAXxjthSZBZf/0e3cFkFnaGrpKhy1ilVUzC37Ewr1nU7LXQbjZJpmcUjN32KXUPPSVAMXETl35ZHwqP7RmSyQg6bbj2VXKNn2+O5qJgDN1b1bLuXshJHuWF4TOCH4dkOahLrz8njtpv5OzBu8nAWfM6/L/lXgK1dkZMAAAAASUVORK5CYII=';

// ❕
$base64-icon-failed: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABGdBTUEAALGPC/xhBQAAAT5JREFUOBGVU61OA0EYnG/bkLRpJZVIBH2FmiJImvAYGAKKx0CguNTAK2CbVBSDxlVgT4JBQ7hlZq+Xtnu7JHzJ3d5+M/P93a4hMv+CMX5wAY8zQkcbuIRhiQ4ebIL1rsSajV/jAO+4I/GSYtf491ZDRWyOEW5sjC9hIUAQf2BB8DQSiPRJ/yjyr3CImYLUmZQ5FkthuGUt0z2xNuJKQ3OhZ5WdMs8yPb5TkFqV1m0Gluu5zwr6yQCaE4fdZQZNO20VehxbLw3SS60yN7+qzTNmtz8CUJsufRtqwADD7bb91aWr5HPShoLnnC0cZzC5S8cMyyzBM7jhLYtT65jhkaQqSTK8ctZPGawKR1ugf8Y9l6skMe8sbIrreog826xiledGiLjS0EKAcDF4trkvsu2IXbdaNPegdum9Y/+9zr/8TlXlAvFvywAAAABJRU5ErkJggg==';

// male
$base64-icon-male: 'data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABGdBTUEAALGPC/xhBQAAARNJREFU
KBVdUT1LQ0EQnL08IyhiFQjW4r/wF2hlcQgWCoqFvViI8BA7QQsR/OhsxEur9v6J/AJBMIhYWQRd
Z1+8y122uNmdnbl3u0+QxW7QeZaHCmwI0CHe3XrZySRweQHBFUX75BaIU8RtXvKYawoDRSt503Lj
QtBW5JOhVnV8Rjs2cux3+O3/SIavHqYjWaBAB5+w2ZpIhnMv36LY5FW/sWlI7uByTT4ilwxGXHt5
oOEkNkXQu/FyGmvDwmCEOrwbWjjF/Sgbn2mYOmj7Ddjie87Ynm0kgteW4oj7fbrwMjBO9oJ2h4Jj
5uvc4VwjnDhsLhU8V4q6GgIvFC5NaIqS/8JRs/oDLFd0L7JbbKZQl8XMH01RRI49Vt5dAAAAAElF
TkSuQmCC';

$base64-icon-female: 'data:img/jpg;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAMCAYAAAC0qUeeAAAABGdBTUEAALGPC/xhBQAAAQdJREFU
KBVdkT1KBEEQhd9bewPBQGVFI8HUXM0MPYKCiqk/64AXUPYAgsIuqwhmXsEziGDiDUzMFlT8CVyn
n+VI9cxOB12vXn1TTFUTlfNz2N+MiMcE5iTeNmewx077w5Hg4ju7WFaMN5ZThamtfMAXk5kzDReI
cVeQNS2P5dvqKDFJWL+lEvtXEibxejnvfoIJttwciTmmPE8woNzNkUgMPa/AbtWiGN1J27Al3Nl0
szbUalEkHim+YUxfDqfOoXuwYR/ce8HAQWgsrPF8/6n0bPt51l+P0AmERS/8RRv62e5uGJ/o8XTn
k8Osd6aIoypU1yQeQqu9Euw/myKv60A1L170/Wr6Fx6jT76WnKQ8AAAAAElFTkSuQmCC';

// ***** mixin 常用utils

// 均匀分布水平空间 by flex
@mixin justify-space-flex {
  display:-webkit-flex; display:flex; -webkit-flex-flow:row wrap; justify-content: space-around;
}
// 均匀分布水平空间 by box
@mixin justify-space-box {
  display:-webkit-box; display:box; -webkit-box-pack:justify;
}
// 隐藏单行文本
@mixin hide-txt {
  overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
}
// 隐藏多行文本，因部分android机型不支持line-clamp，使用时最好定高度
@mixin clamp-txt{
    overflow: hidden; display: -webkit-box; -webkit-box-orient: vertical;
}
// 图片容器，避免图片溢出
@mixin img-box {
  display: block; position: relative; overflow: hidden;
}
// 水平垂直居中，使用相对/绝对定位，宽高不定
@mixin align-hvcenter {
   top: 50%; left:50%; -webkit-transform:translate(-50%, -50%); transform:translate(-50%, -50%);
}
// 图片宽度与父容器相同，垂直居中
@mixin img-fit-w {
  width: 100%; position: relative; top: 50%; -webkit-transform:translate(0, -50%); transform:translate(0, -50%);
}
// 图片高度与父容器相同，水平居中
@mixin img-fit-h {
  height: 100%; position: relative; left:50%; -webkit-transform:translate(-50%, 0); transform:translate(-50%, 0);
}
// 按钮基准
@mixin btn {
   border:0 none; text-align: center; -webkit-transition:all $transition-durition; transition:all $transition-durition; font-size: 14px; border-radius:2px; line-height: 36px; position: relative;
}

// 按钮点击高亮态，通常用在伪类元素上
@mixin hlg {
   content: '\20'; position: absolute; width: 100%; height: 100%; left: 0; top: 0; transition: all $transition-durition; background-color: rgba(0,0,0,0);
}
@mixin hlg-active {
  background-color: rgba(0,0,0,.1);
}
// 小图标基准
@mixin icon {
   background-position:left top; background-repeat:no-repeat; font-size:0;
}
// 表单输入框基准
@mixin control{
    width: px; height:px; background-color: $form-control-bg-color; border-radius: $form-control-border-radius; font-size: $form-control-font-size; color: $form-control-color;  border:1px solid $form-control-border-color;
}
// 固定位置的工具栏基准
@mixin toolbar{
    position: fixed; left: 0; width: 100%; background-color: $toolbar-bg-color;
}
// 覆盖层阴影基准
@mixin overlay{
    position: absolute; left:0; top:0; right:0; bottom:0; z-index: $z-index-overlay;
}
// 弹出对话框基准，水平垂直居中
@mixin dialog{
    position: absolute; background-color:$dialog-bg-color;
}
// 弹出下拉菜单
@mixin drop-menu{ width:134px; z-index: $z-index-drop-menu; position: absolute; background-color:#fff; border-radius:2px; box-shadow:0 4px 10px 0 rgba(0,0,0,.2); padding:10px 0; }


@mixin use-background-image{
    background-size: cover; background-position: center;
}
// 利用边框实现的箭头
@mixin arrow-by-border{
  content:" "; position: absolute; border-top:1px solid #aaa; border-right: 1px solid #aaa; transition:all 0.2s;
}