.group-side-bar{ width: 160px; margin-right: 20px; border: 1px solid #ddd; float: left;
  .add-btn{ display: block; padding: 10px; color: #42bd56; font-size: 14px; }
}
.circle-category-group{ max-height: 680px; overflow: scroll;
  .sub-title{ padding-left: 10px; line-height: 30px; font-size: 14px; color: #ccc; }
  .item{ position: relative; padding-left: 10px; line-height: 40px; font-size: 14px; color: #333;
    .group-op{ display: none; font-size: 12px; color: #42bd56; }
  }
  .item:hover,
  .item.current{ background: #f1f1f1; cursor: pointer; }
  .item:hover{
    &>.group-op{ display: block; position: absolute; right: 5px; top: 0; }
  }
}
.group-list{ height: 100%; max-height: 734px; overflow-y: scroll; 
  .info-col{ width: 45%; text-align: left; }
  .sort-col{ width: 15%; text-align: center; }
  .op-col{ text-align: left; }
}

.group-list .item{ position: relative; border-bottom: 1px solid #e9e9e9; padding: 10px; }
.group-list .item .thumb{ width: 48px; height: 48px; border-radius: 4px; float: left; margin-right: 10px; }
.group-list .item .operation{ line-height: 70px; margin-right: 20px; color: #42bd56; }
.group-list .item .create-btn{ display: block; margin: 0 auto; }
.group-info{ overflow: hidden; }
.group-info .header{ line-height: 24px; } 
.group-info .header .group-name{ font-size: 15px; color: #333; }
.group-info .header .mix-item{ display: inline-block; font-size: 14px; color: #444; }
.group-info .header .mix-item .item{ padding-right: 5px; }
.group-info .header .mix-item .label{ color: #aaa; }
.group-info .desc{ font-size: 14px; color: #aaa; }

.syncgroup-wrap{ margin-bottom: 10px; padding: 10px 0; border-bottom: 1px solid #eee; }
.syncgroup tr:nth-child(n+2){ height: 50px; }
.relative-app-circle{  box-sizing: border-box; display: inline-block; width: 120px; padding-left: 10px; line-height: 30px; border: 1px solid #c4c4c4; border-radius: 2px; }