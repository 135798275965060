@charset "utf-8";

@import "variables.scss";

*{ box-sizing:border-box; }
body, html{ width:100%; height:100%; overflow: hidden;}
html{ -webkit-font-smoothing: antialiased; text-rendering: optimizeLegibility; -webkit-text-size-adjust: 100%; text-size-adjust: 100%; }
body{ font-size: $body-font-size; color: $body-font-color; background-color: $body-bg-color; position: relative; }

a{ color:$anchor-normal-color; text-decoration: none; outline:0 none; transition:all $transition-durition;
  &:hover{  }
}

body,button,input,select,textarea{ font-family: "Segoe UI", "Lucida Grande", Helvetica, "Microsoft YaHei", FreeSans, Arimo, "Droid Sans","wenquanyi micro hei","Hiragino Sans GB", "Hiragino Sans GB W3", Arial, sans-serif; }
p,ul,li,form,img,div,html,body,
h1,h2,h3,dl,dt,dd,
table,input,textarea{ margin: 0; padding: 0;}

input,textarea,button{ border:0 none; outline:0 none; -webkit-appearance: none; appearance: none; box-shadow: none; font-size: $form-control-font-size; transition:all $transition-durition;}
input,textarea{ color:#333; }
input:focus,
textarea:focus{ border-color:$theme-color; box-shadow:0 0 4px 0 rgba(66,189,86,.33); }
button{ background:none; cursor: pointer; padding:0; }
textarea{ resize:none; padding:10px; background-color:#fff; border:1px solid #ebebeb; border-radius:$form-control-border-radius; }
input[type="checkbox"]{ -webkit-appearance: checkbox; appearance: checkbox;}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder{ color: $form-control-plhd-color-n; transition:all $transition-durition; }
input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder{ color: $form-control-plhd-color-f; }
input::-webkit-input-speech-button{display: none;}

table{ border-collapse: collapse; border-spacing: 0;}
ul,ol,dl{ list-style: none; }
img{ border: 0 none; overflow: hidden; }
em,i{ font-style: normal; font-weight: normal; }
audio:not([controls]){display: none; height: 0; }

// 清除浮动
.cf:before,
.cf:after{ content: " "; display: table; }
.cf:after{ clear:both; }

// 主题色缺省类
.theme-border-color{ border-color: $theme-color; }
.theme-bg-color{ background-color : $theme-color; }
.theme-font-color{ color: $theme-color;
    &:hover{ text-decoration: underline;}
}
.bold-txt{ font-weight: bold; }
.font-big{ font-size: 18px; font-weight: bold;}
.font-mid{ font-size: 14px; font-weight: bold;}
.info{ color:#999; }
.desc{ color:#666; }
.time-pick{ padding-right: 32px; background-image: url("/admin/static/imgs/icon_calender.png"); background-position: right center; background-size: 16px; background-repeat: no-repeat; background-color: transparent; }
.time-order{ color:#F5A623; }
.time-fixed{ background-image: url("/admin/static/imgs/icon_time_fixed.png"); color: #d2d2d2; }
.link-jump{ color:#326FA9;
  &:hover{ text-decoration: underline;}
}
// 多行文本隐藏加...
.clamp-txt-line-2{ -webkit-line-clamp: 2;}
// 富文本默认样式
.html-data{ word-wrap: break-word; line-height:$html-data-line-height; color:#666; }
.html-data img { max-width: 100%; vertical-align: text-bottom; margin: $html-data-img-margintb 0; }
// 需要水平居中的图片在布局完成后要加：img-finish-layout
.img-need-layout{ opacity: 0;}
.img-finish-layout{ opacity: 1;}
.img-head{ border-radius:100%; background-color:transparent; }
