@charset "utf-8";

@import "variables.scss";

.magx-list.acticity{ margin-top: 24px;
    tbody td{ padding: 18px 0; vertical-align: top;}
    .title{ line-height: 20px;}
    .img-box{ margin-right: 98px; max-height: 80px; width: 140px;}
    .cover-img{ width: 100%;}
    .magx-btn-text.btn-hspace{ margin-left: 20px;}
}
.activity-post-title{ width: 302px; height: 72px;}
