.feedback-main-content{ width: 100%; height: 100%; border: 1px solid #eee; }
.feedback-chat-overview{ width: 40%; height: 100%; max-width: 325px; padding-top: 10px; float: left; 
  .magx-search-wrap{ overflow: hidden; padding: 0 0 10px 10px; }
}
.feedback-chat-window{ overflow: hidden; }
.feedback-chat-list{ height: 83%; overflow-y: scroll;
  .item{ height: 60px; padding: 10px 0 10px 10px; position: relative; 
    &.active{ background-color: #F3F3F3; 
      .addition{
        .time{ display: none; }
        .btn{ display: inline; }
      }
    }
    &:after{ content: ''; border-bottom: 1px solid #eee; position: absolute; bottom: 0; left: 0; right: 0; }
  }
  .avatar-box{ position: relative; float: left; margin-right: 10px;
    .avatar{ width: 38px; height: 38px; border-radius: 38px; }
    .count{ width: 16px; height: 16px; line-height: 16px; border-radius: 16px; position: absolute; right: 0; top: 0; background-color: #F04056; text-align: center; color: #fff;  }
  }
  .message-info{ line-height: 21px; overflow: hidden;
    .username{ font-size: 14px; color: #333; }
    .content{ padding-right: 48px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: 12px; color: #999; }
  }
  .addition{ position: absolute; right: 10px; top: 20px; font-size: 12px; cursor: pointer;
    .time{ color: #999; }
    .btn{ display: none; color: #42BD56; }
  }
}
.feedback-chat-window{ position: relative; height: 100%; background-color: #F3F3F3; 
  .refresh-btn{ position: absolute; bottom: 35%; right: 24px; }
}
.feedback-message-list{ position: relative; height: 70%; padding: 0 10px 60px; overflow-y: scroll;
  .time{ padding: 20px 0; font-size: 12px; color: #999; text-align: center; }
  .message-main{
    .avatar{ width: 38px; height: 38px; border-radius: 38px; float: left; margin-right: 10px; }
    .content{ display: inline-block; max-width: 280px; padding: 12px 14px; border-radius: 0 8px 8px 8px; background-color: #fff; overflow: hidden; line-height: 25px; font-size: 12px; color: #000; }
    &.contact{
      .avatar{ float: right; }
      .content{ margin-right: 10px; float: right; border-radius: 8px 0 8px 8px; background-color: #42BD56; color: #fff; }
    }
  }
}
.feedback-chat-toolbar{ padding: 12px 0 12px 12px; border-top: 2px solid #ECECEC;
  .tool{ display: inline-block; margin-right: 40px; }
}
.feedback-input-area{ position: relative; width: 100%; height: 20%;
  .content{ width: 100%; height: 100%; background-color: #F3F3F3; font-size: 14px; color: #333; }
  .magx-btn{ position: absolute; bottom: 10%; right: 10px; }
}
.single-chat-list{ margin-top: 20px;
  .item{ position: relative; margin-bottom: 10px; padding: 15px; border: 1px solid #eee; }
  .avatar{ width: 38px; height: 38px; border-radius: 38px; float: left; margin-right: 10px; }
  .info{
    .username{ font-size: 15px; color: #111; }
    .content{ font-size: 12px; color: #6d6d6d; }
  }
  .time{ position: absolute; right: 15px; top: 15px; }
}