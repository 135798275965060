@charset "utf-8";

@import "variables.scss";

.workspace-section{ overflow: hidden; position: relative; padding: 0 20px 60px 0;
  .mod{ padding: 10px 14px 10px 20px;}
  .welcome{ overflow: hidden;
    .img-head{ float: left; margin-right: 14px;}
    .name{ font-size: 14px; font-weight: bold; line-height: 22px; }
    .logout{ font-weight: normal; cursor: pointer;}
  }
  .welcome-opts .item{ float:left; color:#666; margin-right: 10px; padding-right: 10px; position: relative;
    &:after{ content: '\20'; position: absolute; width: 1px; height: 12px; right: 0; top: 4px; background-color: #666;}
    &:last-child:after{ display: none;}
  }
  .welcome-opts .num{ color:#FF5B58; margin-left: 3px;}
  .desc-shortcut{ margin:24px 0 0 0; border-top: 1px solid $single-line-color; padding-top: 10px;}
  .actions{  padding: 6px 16px 16px 64px; border-bottom: 1px solid $single-line-color; overflow: hidden; position: relative; line-height: 20px;
    .field{ font-weight: bold; position: absolute; left:0; top: 6px;}
    .link-jump{ float: left; margin: 0 20px 8px 0; position: relative; line-height: 30px;
        .icon-delete{ display: none; position: absolute; right:-6px; top:-6px; z-index: 1; width: 12px; height:12px; background: url($base64-icon-delete-uploaded-img) no-repeat left top; cursor: pointer;  }
        &:hover .icon-delete{ display: inline; }
        a{ color: inherit; }
    }
    .action-item{ float:left; margin: 0 10px 8px 0; color:#666; cursor: pointer;
        &:hover{ text-decoration: underline;}
    }
  }
  .summary{ margin-top: 18px; text-align: center; color:#fff; overflow: hidden;
    .num{ font-size: 25px; line-height: 30px;}
    .icon{ width: 22px; margin-right: 8px;}
  }
  .summary-user{ width:59%; float: left; background-color: #AB77C2;}
  .summary-new{ width:39.9%; float: right; background-color: #42BD56;}
  .summary-user,
  .summary-new{ position: relative; padding: 14px 0;}
  .summary-user:after,
  .summary-new:after{ content: '\20'; position: absolute; width: 1px; height: 42px; left: 50%; top: 18px; background-color: rgba(0,0,0,.1); }
  .summary-item{ width:50%; float: left; }
  .summary-filter{ margin-top: 22px;
      .options{ margin-top: 15px; }
      .magx-dropselect{ margin-right: 20px; }
      .magx-timepicker{ float: left;
          .magx-textfield{ width: 158px; }
      }
  }

}
.workspace-aside{ float: right; width: 292px;
  .workspace-list-hd{ margin-top: 32px; }
  .forum-list{
      li{ padding: 20px 0 0 0; }
      .magx-thmub{ display: block;
          &:hover{ color: $theme-color; }
      }
      .intro,
      .title,
      .info{ display: block; }
      .title{ line-height: 22px; font-size:14px; }
      .info{ @include hide-txt;}
  }
  .questlist{ margin-top: 10px;
      a{ display: block; line-height: 26px; padding-left: 10px; position: relative; @include hide-txt;
          &:hover{ color: $theme-color; }
          &:after{ content: '\20'; position: absolute; width: 2px; height: 2px; left: 0; top: 12px; background-color: #333; border-radius: 2px; }
        &:hover:after{ background-color: $theme-color; }
      }
  }
}

.workspace-chartbox{ width: 100%; height: 330px; }
.workspace-list-hd{ position: relative; line-height: 22px;
    &.new{ padding-bottom: 10px; border-bottom: 2px solid $single-line-color;}
    .theme-font-color{ position: absolute; right:0; top:0; }
}
.news-list{
  li{ line-height: 48px; overflow: hidden; border-bottom: 1px solid $single-line-color;
    a{ float: left; max-width: 80%; @include hide-txt; font-size: 14px;
      &:hover{ color:$theme-color; }
    }
    .label-new{ float: left; color:#fff; background-color: #FD3E3A; line-height: 14px; padding: 0 6px; margin: 16px 0 0 4px; }
    .time{ float: right; color:#999; }
  }
}

.workspace-customer-service{ display: none; padding: 20px 26px; background-color: #F5F7F5;
  .image-w{ float: left; position: relative;
    .dot{ position:absolute; right:12px; bottom:2px; width: 10px; height: 10px; background-color: #bbbbbb; border-radius: 5px;
      &.active{ background-color: $theme-color; }
    }
  }
  .image{ border-radius: 22px; }
  .intro .title{ font-size: 14px; }
  .info{ @include hide-txt; }
}

.workspace-adarea{ height: 260px; @include use-background-image; }

// 2018.3.30 王同庆 待办事项
.workspace-overview{ width: 100%; margin-bottom: 10px; padding: 10px 0 0; border-bottom: 1px solid #e9e9e9; overflow: hidden;
  .item{ position: relative; width: 18%; max-width: 340px; height: 136px; float: left; margin: 0 20px 20px 0; background-color: #000; color: #fff;
    .count{ position: absolute; right: 20px; top: 15px; font-size: 50px; }
    .name{ position: absolute; right: 20px; bottom: 10px; font-size: 14px; }
    .icon{ position: absolute; left: 20px; top: 20px; }
  }
}

.font-mid{
  &.special{ line-height: 40px; }
  .name{ float: left; margin-right: 20px; }
  .actions{ margin-left: 20px; padding: 7px 0 20px; border: 0;
    .link-jump{ position: relative; display: inline-block; margin: 0; padding: 0 20px; background-color: #F2F2F2;
      .link{ color: #333; font-size: 12px; }
      &:hover{ text-decoration: none; }
    }
  }
}